import React from "react"
import get from 'lodash/get'
import BlockContent from '@sanity/block-content-to-react'
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'
import Img from "gatsby-image"
import { graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { BlogPostNewsletterSignup, RecentBlogPosts, Topography } from "../components/ds"
import { toPlainText } from "../utils"

const BlogPostTemplate = ({ pageContext, data, location }) => {
  const postData = get(data, 'sanityPost')
  const footerData = get(data, 'sanityFooter')
  const locale = get(pageContext, 'locale')

  const publishedAt = DateTime.fromISO(get(postData, 'publishedAt')).setLocale(locale).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)

  const BlockRenderer = props => {
    const { style = 'normal' } = props.node

    if (style === 'h1') {
      return <h2>{props.children}</h2>
    }

    if (style === 'blockquote') {
      return <blockquote>- {props.children}</blockquote>
    }

    return BlockContent.defaultSerializers.types.block(props)
  }

  const serializers = {
    types: {
      block: BlockRenderer,
      youtube: ({ node }) => {
        const { url } = node
        const id = getYouTubeId(url)
        return (
          <div className="flex justify-center">
            <YouTube videoId={id} />
          </div>
        )
      }
    },
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = '' } = mark
        const href = `/${slug}`
        return <Link to={href}>{children}</Link>
      },
      link: ({ mark, children }) => {
        const { blank, href } = mark
        const classNames =
          'font-medium underline text-vivid-light-blue-700 hover:text-vivid-light-blue-600 transition ease-in-out duration-150'
        return blank ? (
          <a href={href} className={classNames} target="_blank" rel="noreferrer">
            {children}
          </a>
        ) : (
            <a href={href} className={classNames}>
              {children}
            </a>
          )
      },
    },
  }

  return (
    <>
      <SEO
        title={get(postData, 'title')}
        description={toPlainText(get(postData, '_rawExcerpt'))}
        lang={locale}
        pathname={get(location, 'pathname')}
        image={get(postData, 'seoThumbnail.asset.localFile.childImageSharp.resize')}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
            <div className="relative h-full text-lg max-w-prose mx-auto">
              <Topography className="absolute top-12 left-full transform translate-x-32" />
              <Topography className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" />
              <Topography className="absolute bottom-12 left-full transform translate-x-32" />
            </div>
          </div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto mb-6">
              <h1 className="text-3xl text-center leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">{get(postData, 'title')}</h1>
              <div className="mt-8 flex justify-center">
                <div className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <Img className="inline-block h-9 w-9 rounded-full" fluid={get(postData, 'author.avatar.asset.localFile.childImageSharp.fluid')} />
                    <div className="ml-3">
                      <p className="text-base leading-6 font-medium text-cool-grey-700 tracking-wide">
                        {get(postData, 'author.firstname')} {get(postData, 'author.lastname')}
                      </p>
                      <p className="text-sm leading-5 font-medium text-cool-grey-500 transition ease-in-out duration-150">
                        {publishedAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prose prose-lg text-cool-grey-500 mx-auto">
              <figure>
                <Img fluid={get(postData, 'mainImage.asset.localFile.childImageSharp.fluid')} className="w-full rounded-lg" />
                {get(postData, 'imageCredit') ? <figcaption>{get(postData, 'imageCredit')}</figcaption> : null}
              </figure>
              <BlockContent blocks={get(postData, '_rawBody')} serializers={serializers} />
            </div>
          </div>
        </div>
        <BlogPostNewsletterSignup locale={locale} />
        <RecentBlogPosts locale={locale} />
      </Layout>
    </>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPost($id: String, $locale: String) {
    sanityPost(_id: {eq: $id}) {
      title
      publishedAt
      imageCredit
      categories {
        _id
        description
        title
      }
      author {
        firstname
        lastname
        role {
          en
          sv
        }
        avatar {
          asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      mainImage {
        asset {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      seoThumbnail {
        asset {
          localFile {
            childImageSharp {
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
        }
      }
      _rawExcerpt
      _rawBody
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
